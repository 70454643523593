@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

.wrapper {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
  }

  &__control {
    position: relative;
  }

  &__input {
    outline: none !important;
    width: 100%;
    border: 1px solid get-color("border");
    border-radius: get-border-radius(s);
    padding: 9px 15px;
    font-size: get-font(l);
    font-family: "Graphik LCG", serif;

    &::placeholder {
      color: get-color('placeholder');
    }

    &:disabled {
      background-color: #EFEFEF;
    }

    &:focus {
      border: 1px solid get-color("black-color") !important;
    }

    &:focus:read-only {
      border: 1px solid get-color("border") !important;;
    }

    &[data-invalid] {
      border: 1px solid get-color("red-color") !important;
    }

    &[type=number] {
      -moz-appearance:textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        // margin: 0;
    }
  }

  &__error_triangle {
    position: absolute;
    color: get-color(red-color);
    right: 12px;
    bottom: 9px;
  }

  &__error_label {
    display: flex;
    color: var(--red-color);
    font-size: 12px;
    margin-top: 4px;
  }
}
