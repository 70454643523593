@import "../../styles/helpers/functions";
@import "../../styles/helpers/mixins";
@import "../../styles/helpers/keyframes";
@import "../../styles/settings/variables";
@import "../../styles/settings/classes";
@import "../../styles/settings/overrides";
@import "../../styles/settings/fonts";

.reset_password {

  &__title {
    font-size: get-font(title);
    font-weight: 600;
    margin-bottom: get-margin(l);
  }

  &__description {
    font-size: get-font('description');
    color:  get-color('text-grey');
    margin-bottom: get-margin(xl);
  }

  &__form {
    @include column-block;
  }

  &__submit {
    margin-top: get-margin(xxxxl) !important;
  }

  &__helper {
    padding-left: get-padding(xl);
    color: get-color('grey-color');
    font-size: 13px;
    margin-top: get-margin(m);

    li {
      list-style-type: none;
    }
  }

}
