@import "../../styles/helpers/functions";
@import "../../styles/helpers/mixins";
@import "../../styles/helpers/keyframes";
@import "../../styles/settings/variables";
@import "../../styles/settings/classes";
@import "../../styles/settings/overrides";
@import "../../styles/settings/fonts";

.content {
  height: calc(100vh - #{$header-height} - #{$page-title-height} - #{$tabs-height});
  padding: get-padding(xl) $page-padding;
  overflow: auto;
}


.card {
  min-height: 100px;
  border-radius: 10px;
  background-color: get-color(white);
  padding: get-padding(xxl) get-padding(xxxl);
  margin-bottom: 100px;
  box-shadow: $card-box-shadow;
}


.info {
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  position: relative;

  &__title {
    font-size: get-font(xxl);
    font-weight: 600;
  }

  &__content {
    @include column-block;

    gap: 10px;

    &__buttons {
      @include inline-block;

      gap: 10px;
    }
  }
}
