// common classes

@include margin-padding-classes;

%flex {
  display: flex;
}

.box-shadow {
  box-shadow: 4px 0 20px 0px rgba(0, 0, 0, 0.15);
}

.flex-row {
  @extend %flex;
  flex-direction: row;
}

.flex-column {
  @extend %flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.center {
  justify-content: center;
}

.baseline {
  align-items: baseline;
}

.align-center {
  align-items: center;
}
