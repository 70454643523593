@import "../../../../styles/helpers/functions";
@import "../../../../styles/helpers/mixins";
@import "../../../../styles/helpers/keyframes";
@import "../../../../styles/settings/variables";
@import "../../../../styles/settings/classes";
@import "../../../../styles/settings/overrides";
@import "../../../../styles/settings/fonts";

.rewards {
  display: grid;
  grid-template-columns: 1fr 640px 1fr;

  &__title {
    font-size: get-font(xxl);
    font-weight: 600;
  }

  &__wrapper {
    display: grid;
    gap: 20px;
  }

  &__content {
    &__header {
      display: flex;
      justify-content: space-between;
      padding-bottom: get-padding(xl);
      margin-bottom: get-margin(xl);
      border-bottom: 1px solid get-color(border);

      svg {
        cursor: pointer;
      }

      p {
        color: get-color(option);
        font-weight: 500;
      }
    }

    &__radios {
      padding: get-padding(m) 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__add_more {
    color: get-color(primary);
    font-weight: 500;
    display: flex;
    gap: 7px;
    margin-top: get-margin(m);
    font-size: get-font(l);
    cursor: pointer;
  }
}
