@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

.confirm_dialog {
  @include column-block;

  width: 100%;

  &__title {
    font-size: 19px;
    padding-top: get-padding(l);
    padding-bottom: get-padding(s);
    font-weight: 600;
  }

  &__description {
    font-size: get-font(l);
    line-height: 22px;
    color: get-color('text-secondary');
  }

  &__warning_icon {
    width: 40px;
    height: 40px;
    background-color: get-color('warning-bg');
    border-radius: get-border-radius(xxl);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }

  &__buttons_container {
    @include inline-block;

    margin-top: 33px;
    margin-bottom: get-margin(xl);
    justify-content: flex-end;
  }

  &__close_button {
    background-color: white;
  }

  &__submit_button {
    margin-left: 10px;
  }

  &__icon_container {
    display: flex;
    justify-content: space-between;

    &__close {
      background-color: white;
      outline: none;

      &__icon {
        cursor: pointer;
        position: relative;
        bottom: 15px;
        left: 5px;
      }
    }
  }
}

.dialog {
  &__green {
    color: get-color('primary');
  }

  &__orange {
    color: get-color('warning');
  }

  &__red {
    color: get-color('red-color');
  }

  &__bg {
    &_green {
      background-color: get-color('bg-primary');
    }

    &_orange {
      background-color: get-color('warning-bg');
    }

    &_red {
      background-color: get-color('error-bg');
    }
  }

  &__overlay {
    background-color: #202122;
    position: fixed;
    inset: 0;
    opacity: 0.7;
    animation: 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &__content {
    background-color: white;
    border-radius: 4px;
    box-shadow: #{'hsl(206 22% 7% / 35%) 0px 10px 38px -10px'}, #{'hsl(206 22% 7% / 20%) 0px 10px 20px -15px'};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 440px;
    max-width: 450px;
    min-height: 190px;
    padding: get-padding(xl);
    animation: 150ms cubic-bezier(0.16, 1, 0.3, 1);
    display: flex;
    justify-content: center;
    padding-bottom: 0;
    max-height: 80vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
