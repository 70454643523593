@mixin custom-scrollbar-vertical {
  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 20px 20px grey;
    border: solid 8px transparent;
    border-radius: 45px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 20px 20px grey;
    border: solid 8px transparent;
    border-radius: 45px;
  }
}
@mixin custom-scrollbar-horizontal {
  &::-webkit-scrollbar {
    height: 20px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 20px 20px 0 0 grey;
    border: solid 8px transparent;
    border-radius: 45px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 20px 20px 0 0 grey;
    border: solid 8px transparent;
    border-radius: 45px;
  }
}

@mixin inline-block {
  display: flex;
  flex-direction: row;
}

@mixin column-block {
  display: flex;
  flex-direction: column;
}

@mixin loader {
  -webkit-animation: rotating 4s linear infinite;
}

@mixin margin-padding-classes {
  @for $i from 0 through 50 {
    $value: $i + px;
    .m-#{$i} {
      margin: $value !important;
    }
    .ml-#{$i} {
      margin-left: $value !important;
    }
    .mr-#{$i} {
      margin-right: $value !important;
    }
    .mt-#{$i} {
      margin-top: $value !important;
    }
    .mb-#{$i} {
      margin-bottom: $value !important;
    }
    .mx-#{$i} {
      margin-left: $value !important;
      margin-right: $value !important;
    }
    .my-#{$i} {
      margin-top: $value !important;
      margin-bottom: $value !important;
    }

    /* padding #{$value} */
    .p-#{$i} {
      padding: $value !important;
    }
    .pl-#{$i} {
      padding-left: $value !important;
    }
    .pr-#{$i} {
      padding-right: $value !important;
    }
    .pt-#{$i} {
      padding-top: $value !important;
    }
    .pb-#{$i} {
      padding-bottom: $value !important;
    }
    .px-#{$i} {
      padding-left: $value !important;
      padding-right: $value !important;
    }
    .py-#{$i} {
      padding-top: $value !important;
      padding-bottom: $value !important;
    }
  }
  .m-a {
    margin: auto;
  }
}

@mixin grid($nested: false, $gutters: false, $gutterSize: 15px) {
  @include inline-block();
  box-sizing: border-box;

  @if $nested {
    flex: 0 1 auto;
  }

  @if $gutters {
    > * {
      padding-left: $gutterSize;
      padding-right: $gutterSize;
      box-sizing: border-box;
    }
  }
}

@mixin fillSpace {
  flex: 1 0 0;
  width: auto;
}

@mixin main-page-button {
  margin: auto;
  z-index: 1;
}

@mixin grid-item($columns: 0, $sm: null, $md: null, $lg: null) {
  $width: get-columns-width();
  $flex-basis: $width * $columns;

  @if ($columns == 0) {
    @include fillSpace;
  } @else {
    flex: 0 0 $flex-basis;
    min-width: $flex-basis;
  }

  @if ($sm != null) {
    @media screen and (min-width: $tablet) {
      $flex-basis: $width * $sm;
      @if ($md == 0) {
        @include fillSpace;
      } @else {
        flex: 0 0 $flex-basis;
        min-width: $flex-basis;
      }
    }
  }

  @if ($md != null) {
    @media screen and (min-width: $small_desktop) {
      $flex-basis: $width * $md;
      @if ($md == 0) {
        @include fillSpace;
      } @else {
        flex: 0 0 $flex-basis;
        min-width: $flex-basis;
      }
    }
  }

  @if ($lg != null) {
    @media screen and (min-width: $desktop) {
      $flex-basis: $width * $lg;
      @if ($lg == 0) {
        @include fillSpace;
      } @else {
        flex: 0 0 $flex-basis;
        min-width: $flex-basis;
      }
    }
  }
}
