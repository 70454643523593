@import "../../styles/helpers/functions";
@import "../../styles/helpers/mixins";
@import "../../styles/helpers/keyframes";
@import "../../styles/settings/variables";
@import "../../styles/settings/classes";
@import "../../styles/settings/overrides";
@import "../../styles/settings/fonts";

.content {
  height: calc(100vh - #{$header-height} - #{$page-title-height} - #{$tabs-height});
  padding: get-padding(xl) $page-padding;
  overflow: auto;
}

.dialog {
  &__submit_button {
    margin-left: get-margin(m);
  }

  &__buttons_container {
    display: flex;
    margin-top: 33px;
    justify-content: flex-end;
    padding-bottom: get-padding(xl);
  }

  &__add_button {
    display: flex;
    gap: 20px;
    align-items: center;
    border: none;
    margin-left: 0;
    margin-top: get-margin(xl);
    padding-left: 0;
    padding-right: 0;
    width: 40%;
    cursor: pointer;
  }

  &__add_icon {
    width: 20px;
    height: 20px;
    color: get-color('primary');
  }

  &__add_text {
    margin-left: -15px;
    font-weight: 500;
    color: get-color('primary');
  }

  &__title {
    margin: 0;
    font-size: get-font(xxl);
    font-weight: 600;
  }

  &__description {
    margin-top: 5px;
    color: get-color('text-grey');
    font-size: get-font(l);
    line-height: 1.5;
    margin-right: 20%;
  }
}

.dialog_success {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    font-size: get-font(xxl);
    padding: get-padding(xl) 0;
    font-weight: 600;
  }

  &__check_icon {
    width: 25px;
    height: 25px;
    color: get-color('primary');
  }

  &__buttons_container {
    display: flex;
    margin-top: 33px;
    justify-content: flex-end;
  }

  &__close_button {
    background-color: white;
  }
}

.dialog_inputs {
  margin-top: get-margin(l);

  &__label_container {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    font-size: get-font(l);
    font-weight: 500;
    margin-bottom: get-margin(m);
    color: get-color('text-grey');
  }

  &__select {
    margin-bottom: get-margin(l);
  }

  &__trash_icon {
    width: 20px;
    height: 20px;
    color: get-color('primary');
  }
}

.dialog_delete {
  &__description {
    line-height: 1.3;
  }
}
