@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

.wrapper {
  input[type="checkbox"] {
    display: none;
  }

  &__label {
    @include inline-block;

    cursor: pointer;
    gap: get-padding(m);
    align-items: center;

    &__dot {
      width: 20px;
      border-radius: get-border-radius(s);
      height: 20px;
      border: 1px solid get-color("border");
      cursor: pointer;
      position: relative;

      &--selected {
        background-color: get-color("primary");
        border: 1px solid get-color("primary");
      }

      &::after {
        content: url("../../../assets/svg/shape.svg");
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
