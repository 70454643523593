@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

@mixin icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}


.wrapper {
  position: relative;

  &__columns {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__content {
    &--col-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    margin-top: -3px;

    &__filters {
      border-left: 1px solid get-color("black-color");
      border-bottom-left-radius: get-border-radius(s);
      border-bottom: 1px solid get-color("black-color");
      padding: get-padding(m);
      font-size: 15px;
      cursor: pointer;

      &__title {
        font-weight: 500;
        padding: get-padding(s) 0 get-padding(m) 0;
      }

      &__item {
        &--checked {
          background: get-color(light-gray);
        }

        padding: get-padding(m);
        border-radius: get-border-radius(s);
        display: flex;
        justify-content: space-between;
      }
    }

    &__selects {
      width: 100%;
      z-index: 1;
      background: get-color(white);

      &__menu {
        @include column-block;

        &__title {
          padding: get-padding(m);
        }

        border-left: 1px solid get-color("black-color");
        border-right: 1px solid get-color("black-color");
        overflow: auto;
        height: 300px;
        padding: 8px 0;
        position: relative;
        font-size: get-font(m);

        &__not_found {
          padding: 8px 12px;
          font-weight: 500;
        }

        &__statistic {
          &--roundness-border {
            border-bottom-left-radius: get-border-radius(s);
          }

          border-left: 1px solid get-color("black-color");
          border-right: 1px solid get-color("black-color");
          border-bottom: 1px solid get-color("black-color");
          border-bottom-right-radius: get-border-radius(s);
          background: get-color(light-gray);
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 12px;
          justify-content: space-between;
          font-size: get-font(m);

          span {
            color: get-color(primary);
            cursor: pointer;
            padding-right: 7px;
          }
        }

        &__label {
          padding: 6px get-padding(m);
          font-size: get-font(m);
        }
      }
    }
  }

  &__chips {
    padding-bottom: get-padding(m);
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    img {
      padding-left: get-padding(m);
      cursor: pointer;
    }

    &__item {
      flex: 0 0 auto;
      background: get-color("blue-white");
      color: get-color(primary);
      font-size: get-font(l);
      padding: get-padding(m);
      border-radius: get-border-radius(s);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      justify-content: center;
      display: flex;

      &__icon {
        cursor: pointer;
        margin-left: get-padding(s);

        path {
          fill: get-color(active-bg-btn-grey);
        }
      }
    }
  }

  &__search {
    position: relative;

    &__right_icon {
      right: 10px;

      @include icon;

      cursor: pointer;
    }

    &__left_icon {
      left: 10px;

      @include icon;
    }

    &__input {
      position: relative;
      outline: none !important;
      width: 100%;
      z-index: 2;
      border: 1px solid get-color("border");
      border-radius: get-border-radius(s);
      padding: 9px 32px;
      font-size: get-font(l);
      font-family: $font-family;

      &:focus {
        border: 1px solid get-color("black-color") !important;
      }
    }

    &__select {
      border: 1px solid get-color("black-color") !important;
    }
  }
}

.invisible {
  display: none;
}
