@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

.label {
  font-size: get-font(l);
  font-weight: 500;
  padding-bottom: get-padding(m);
  display: inline-block;
  position: relative;
}
