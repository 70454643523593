@import "../../styles/helpers/functions";
@import "../../styles/helpers/mixins";
@import "../../styles/helpers/keyframes";
@import "../../styles/settings/variables";
@import "../../styles/settings/classes";
@import "../../styles/settings/overrides";
@import "../../styles/settings/fonts";

.content {
  height: calc(100vh - #{$header-height} - #{$page-title-height} - #{$tabs-height});
  padding: get-padding(xl) $page-padding;
  overflow: auto;
}
