:root {
    --primary: #0073F7;
    --secondary: #F3F3FF;
    --disabled-light-color: #C5D0DB;
    --disabled-text-light-color: #F7FAFF;
    --background-color: #F7FAFF;
    --red-light-color: rgba(255, 0, 0, 0.04);
    --red-color: #EB002F;
    --black-color: #202122;
    --grey-color: #888D93;
    --grey-color-150: #A5AAAF;
    --grey-color-100: #D5D9DD;
    --grey-color-50: #E1E5EA;
    --grey-color-25: #ECEFF3;
    --font-size: 14px;
    --font-family: "Graphik LCG", serif;
}
