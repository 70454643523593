@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

@mixin icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}


.wrapper {
  position: relative;

  &__error {
    display: flex;
    color: var(--red-color);
    font-size: 12px;
    margin-top: 4px;
  }

  &__content {
    position: absolute;
    background: get-color(white);
    width: 100%;
    z-index: 4;
    margin-top: -3px;
    border: 1px solid get-color(black-color);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 9px 15px;
    max-height: 300px;
    overflow-y: scroll;

    &__item {
      &--selected {
        font-weight: 500;
      }

      &:hover {
        color: get-color(primary);
      }

      &--invisible {
        display: none;
      }

      &--titling {
        &:not(:first-child) {
          border-top: 1px solid get-color(grey-color-100);
          padding-top: 17px;
          margin-top: get-margin(s);
        }

        &:hover {
          color: get-color(grey-color);
        }

        cursor: initial;
        display: block;
        text-transform: uppercase;
        color: get-color(grey-color);
        padding: get-padding(m) 0;
      }

      padding: 6px 0;
      font-size: get-font(m);
      cursor: pointer;
    }
  }

  &__search {
    position: relative;

    &__right_icon {
      @include icon;

      right: 10px;
      cursor: pointer;
    }

    &__left_icon {
      @include icon;

      left: 10px;
    }

    &__input {
      &--selected {
        border: 1px solid get-color("black-color") !important;
      }

      &--with_search {
        padding: 9px 32px 9px 15px;
        cursor: pointer;
        z-index: 1;
      }

      &:focus {
        border: 1px solid get-color("black-color") !important;
      }

      &--error {
        border: 1px solid get-color("red-color") !important;
      }

      position: relative;
      outline: none !important;
      width: 100%;
      z-index: 2;
      border: 1px solid get-color("border");
      border-radius: get-border-radius(s);
      padding: 9px 32px;
      font-size: get-font(l);
      font-family: $font-family;
    }
  }
}
