@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

%arrow {
  width: 15px;
  height: 15px;

  &::before {
    display: none;
  }
}

%area {
  background: get-color(primary);
  border-radius: 50%;
  color: white;
}

.wrapper {
  position: relative;
  z-index: 4;

  &--checked {
    border: 1px solid get-color(black-color) !important;
  }

  &__error {
    display: flex;
    color: var(--red-color);
    font-size: 12px;
    margin-top: 4px;
  }

  div {
    font-family: $font-family;

    div[class="react-datepicker"] {
      border: 1px solid get-color(black-color);
      z-index: 15;

      span[class="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"] {
        background: url("../../../assets/svg/arrow-left.svg") no-repeat;

        @extend %arrow;
      }

      span[class="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"] {
        background: url("../../../assets/svg/arrow-right.svg") no-repeat;

        @extend %arrow;
      }

      div[class="react-datepicker__triangle"] {
        display: none;
      }

      div[class="react-datepicker__current-month"] {
        font-weight: 500;
      }

      div[aria-selected="false"] {
        &:hover {
          @extend %area;
        }
      }

      div[aria-selected="true"] {
        @extend %area;

        font-weight: 600;
      }

      div[class="react-datepicker__header "] {
        background: get-color(white);
        border-bottom: 1px solid;
      }
    }
  }

  input {
    width: 100%;
    outline: none !important;
    border: 1px solid get-color(border);
    border-radius: get-border-radius(s);
    padding: 12px;
    font-size: get-font(l);
    max-height: 35px;
    padding-left: 30px;

    &:focus {
      border: 1px solid get-color(black-color) !important;
    }
  }
}

.custom_datepicker_icon {
  position: absolute;
  top: 17px;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 1;
  color: get-color(grey-color);
}
