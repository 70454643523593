@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

.tooltip {
  &__content {
    border-radius: get-border-radius(s);
    padding: get-padding(m) get-padding(l);
    font-size: get-font(l);
    line-height: 1;
    color: get-color('text-primary');
    background-color: white;
    box-shadow: #{'hsl(206 22% 7% / 35%) 0px 10px 38px -10px'}, #{'hsl(206 22% 7% / 20%) 0px 10px 20px -15px'};
    user-select: none;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;

    &[data-state='delayed-open'][data-side='top'] {
      animation-name: slide-down-and-fade;
    }

    &[data-state='delayed-open'][data-side='right'] {
      animation-name: slide-left-and-fade;
    }

    &[data-state='delayed-open'][data-side='bottom'] {
      animation-name: slide-up-and-fade;
    }

    &[data-state='delayed-open'][data-side='left'] {
      animation-name: slide-right-and-fade;
    }
  }

  &__arrow {
    fill: white;
  }
}
