@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

.tabs {
  @include column-block;

  &__list {
    @include inline-block;

    padding: 0 $page-padding;
    flex-shrink: 0;
    border-bottom: 1px solid get-color('grey-color-100');
  }

  &__trigger {
    @include inline-block;

    padding: get-padding(s) get-padding(xl) get-padding(l);
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: get-font(l);
    line-height: 25px;
    color: get-color('text-grey');
    user-select: none;
    cursor: pointer;
    background-color: transparent;

    &:first-child {
      border-top-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
    }

    &:hover {
      color: get-color('text-primary');
    }

    &[data-state='active'] {
      color: get-color('primary');
      box-shadow: inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor;
    }

    // &:focus {
    //   position: relative;
    //   box-shadow: 0 0 0 2px black;
    // }
  }
}
