@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

.wrapper {
  &__textarea {
    border: 1px solid get-color("border");
    border-radius: get-border-radius(s);
    padding: 9px 15px;
    font-size: get-font(l);;
    resize: none;
    width: 100%;
    font-family: "Graphik LCG", sans-serif;

    &[data-invalid] {
      border: 1px solid get-color("red-color") !important;
    }

    &:focus {
      border: 1px solid get-color("black-color");
      outline: none !important;
    }

    &::placeholder {
      color: get-color('placeholder');
    }

    &:disabled {
      background-color: #EFEFEF;
    }
  }

  &__error_label {
    display: flex;
    color: var(--red-color);
    font-size: 12px;
    margin-top: 4px;
  }
}
