@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

.page_title {
  @include inline-block;

  padding: get-padding(xxl) $page-padding get-padding(m);
  justify-content: space-between;

  &__text {
    @include inline-block;

    align-items: center;
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    color: get-color('text-primary');

    b {
      color: get-color('text-secondary');
      font-weight: 600;
      cursor: pointer;
    }

    svg {
      margin: 0 get-margin(s);
    }

    span {
      margin-left: get-margin(m);
      font-weight: 600;
      font-size: get-font(xxl);
      color: get-color('placeholder');
    }
  }


  &__action {
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    color: get-color('black-color');

    a {
      text-decoration: none;
    }
  }
}
