@import "../../../../styles/helpers/functions";
@import "../../../../styles/helpers/mixins";
@import "../../../../styles/helpers/keyframes";
@import "../../../../styles/settings/variables";
@import "../../../../styles/settings/classes";
@import "../../../../styles/settings/overrides";
@import "../../../../styles/settings/fonts";

@mixin title {
  font-size: get-font(l);
  font-weight: 500;
}

.info {
  display: grid;
  grid-template-columns: 1fr 640px 1fr;

  &__title {
    font-size: get-font(xxl);
    font-weight: 600;
  }

  &__content {
    @include column-block;

    gap: 30px;

    &__multiselect {
      &__title {
        @include title;

        padding-bottom: get-padding(m);
      }
    }

    &__input {
      z-index: auto;
    }
  }
}
