@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-MediumItalic.eot');
  src: local('Graphik LCG Medium Italic'), local('GraphikLCG-MediumItalic'),
  url('../../assets/fonts/GraphikLCG-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-MediumItalic.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-MediumItalic.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-SuperItalic.eot');
  src: local('Graphik LCG Super Italic'), local('GraphikLCG-SuperItalic'),
  url('../../assets/fonts/GraphikLCG-SuperItalic.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-SuperItalic.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-SuperItalic.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-SuperItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-SemiboldItalic.eot');
  src: local('Graphik LCG Semibold Italic'), local('GraphikLCG-SemiboldItalic'),
  url('../../assets/fonts/GraphikLCG-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-SemiboldItalic.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-SemiboldItalic.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-Black.eot');
  src: local('Graphik LCG Black'), local('GraphikLCG-Black'),
  url('../../assets/fonts/GraphikLCG-Black.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-Black.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-Black.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-Super.eot');
  src: local('Graphik LCG Super'), local('GraphikLCG-Super'),
  url('../../assets/fonts/GraphikLCG-Super.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-Super.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-Super.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-Super.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-Thin.eot');
  src: local('Graphik LCG Thin'), local('GraphikLCG-Thin'),
  url('../../assets/fonts/GraphikLCG-Thin.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-Thin.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-Thin.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-ExtralightItalic.eot');
  src: local('Graphik LCG Extralight Italic'), local('GraphikLCG-ExtralightItalic'),
  url('../../assets/fonts/GraphikLCG-ExtralightItalic.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-ExtralightItalic.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-ExtralightItalic.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-ExtralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-Regular.eot');
  src: local('Graphik LCG Regular'), local('GraphikLCG-Regular'),
  url('../../assets/fonts/GraphikLCG-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-Regular.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-Regular.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-BoldItalic.eot');
  src: local('Graphik LCG Bold Italic'), local('GraphikLCG-BoldItalic'),
  url('../../assets/fonts/GraphikLCG-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-BoldItalic.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-BoldItalic.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-LightItalic.eot');
  src: local('Graphik LCG Light Italic'), local('GraphikLCG-LightItalic'),
  url('../../assets/fonts/GraphikLCG-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-LightItalic.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-LightItalic.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-BlackItalic.eot');
  src: local('Graphik LCG Black Italic'), local('GraphikLCG-BlackItalic'),
  url('../../assets/fonts/GraphikLCG-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-BlackItalic.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-BlackItalic.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-Medium.eot');
  src: local('Graphik LCG Medium'), local('GraphikLCG-Medium'),
  url('../../assets/fonts/GraphikLCG-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-Medium.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-Medium.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-Light.eot');
  src: local('Graphik LCG Light'), local('GraphikLCG-Light'),
  url('../../assets/fonts/GraphikLCG-Light.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-Light.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-Light.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-Semibold.eot');
  src: local('Graphik LCG Semibold'), local('GraphikLCG-Semibold'),
  url('../../assets/fonts/GraphikLCG-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-Semibold.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-Semibold.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-Bold.eot');
  src: local('Graphik LCG Bold'), local('GraphikLCG-Bold'),
  url('../../assets/fonts/GraphikLCG-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-Bold.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-Bold.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-Extralight.eot');
  src: local('Graphik LCG Extralight'), local('GraphikLCG-Extralight'),
  url('../../assets/fonts/GraphikLCG-Extralight.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-Extralight.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-Extralight.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-ThinItalic.eot');
  src: local('Graphik LCG Thin Italic'), local('GraphikLCG-ThinItalic'),
  url('../../assets/fonts/GraphikLCG-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-ThinItalic.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-ThinItalic.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('../../assets/fonts/GraphikLCG-RegularItalic.eot');
  src: local('Graphik LCG Regular Italic'), local('GraphikLCG-RegularItalic'),
  url('../../assets/fonts/GraphikLCG-RegularItalic.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GraphikLCG-RegularItalic.woff2') format('woff2'),
  url('../../assets/fonts/GraphikLCG-RegularItalic.woff') format('woff'),
  url('../../assets/fonts/GraphikLCG-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

