@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

.wrapper {
  background-color: white;
  padding: get-padding(xl) get-padding(xxxl);
  margin-top: get-margin(xxxl) * 2;
  border-top: 1px solid get-color(border);
  display: flex;
  width: 100%;
  right: 0;
  justify-content: flex-end;
  bottom: 0;
  gap: 10px;
}
