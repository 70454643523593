@import "../../../../styles/helpers/functions";
@import "../../../../styles/helpers/mixins";
@import "../../../../styles/helpers/keyframes";
@import "../../../../styles/settings/variables";
@import "../../../../styles/settings/classes";
@import "../../../../styles/settings/overrides";
@import "../../../../styles/settings/fonts";

@mixin title {
  font-size: get-font(l);
  font-weight: 500;
}

.store {
  display: grid;
  grid-template-columns: 1fr 640px 1fr;

  &__title {
    font-size: get-font(xxl);
    font-weight: 600;
  }

  &__content {
    &--col-2 {
      grid-template-columns: 1fr 1fr;
    }

    &--col-6 {
      grid-template-columns: 60px 0.5fr 50px 1fr 0.5fr 1fr;
    }

    display: grid;
    gap: 30px;
    align-items: center;

    &__inputs {
      gap: 10px;
      display: grid;
      align-items: center;
    }

    &__radio {
      &__title {
        @include title;

        &__clickable {
          padding-top: get-padding(m);
          cursor: pointer;
          color: get-color(red-color);
        }
      }

      &__radios {
        &--col-2 {
          grid-template-columns: repeat(2, 1fr);
        }

        &--col-3 {
          grid-template-columns: repeat(3, 1fr);
        }

        display: grid;
        gap: 10px;
      }
    }

    &__date_inputs {
      gap: 10px;
      display: grid;
      align-items: center;
      z-index: 1;

      &__date_wrapper {
        z-index: auto;
      }
      
    }
  }
}
