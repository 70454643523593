@import "../../../../styles/helpers/functions";
@import "../../../../styles/helpers/mixins";
@import "../../../../styles/helpers/keyframes";
@import "../../../../styles/settings/variables";
@import "../../../../styles/settings/classes";
@import "../../../../styles/settings/overrides";
@import "../../../../styles/settings/fonts";

@mixin title {
  font-size: get-font(l);
  font-weight: 500;
}

.limits {
  display: grid;
  grid-template-columns: 1fr 640px 1fr;

  &__title {
    font-size: get-font(xxl);
    font-weight: 600;
  }

  &__content {
    &--col-2 {
      grid-template-columns: 1fr 1fr;
    }

    &--col-6 {
      grid-template-columns: 60px 0.5fr 50px 1fr 0.5fr 1fr;
    }

    display: grid;
    gap: 30px;

    &__inputs {
      gap: 10px;
      display: grid;
      align-items: center;
    }

  }
}
