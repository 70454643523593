@import "helpers/functions";
@import "helpers/mixins";
@import "helpers/keyframes";
@import "settings/variables";
@import "settings/classes";
@import "settings/overrides";
@import "settings/fonts";

@import "settings/settings";
@import "global/globals";

input, textarea, button {
  font-family: $font-family;
}
