@import "vars.css";

.auth-container {
  margin: 7% auto;
  padding: 60px 110px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  border-radius: 20px;
  width: 640px;
  min-height: 180px;
  position: relative;
}

.auth-title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.button {
  height: 35px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
}

.secondary-button {
  color: var(--primary);
  border: 1px var(--secondary) solid;
  background-color: var(--secondary);
}

.description-text {
  margin-top: 20px;
  color: var(--grey-color);
}

Input:focus {
  border: 2px solid var(--black-color) !important;
}

.icon-color {
  color: var(--primary);
  width: 20px;
  height: 22px;
}

.react-datepicker-popper {
  z-index: 4;
}