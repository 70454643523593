@import "../../styles/helpers/functions";
@import "../../styles/helpers/mixins";
@import "../../styles/helpers/keyframes";
@import "../../styles/settings/variables";
@import "../../styles/settings/classes";
@import "../../styles/settings/overrides";
@import "../../styles/settings/fonts";

.sign_up {
  &__title {
    font-size: get-font(xxxxl);
    font-weight: 600;
    margin-bottom: get-margin(xxl);
  }

  &__form {
    @include column-block;
  }

  &__row_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: get-margin(xl);
  }

  &__forgot_password_link {
    font-size: get-font(l);
    text-decoration: none;
    color: get-color('primary');

    &:hover {
      color: get-color('black-color');
    }

    &:disabled {
      color: get-color('text-grey');
    }
  }

  &__helper {
    padding-left: get-padding(xl);
    color: var(--grey-color);
    font-size: 13px;
    margin-top: get-margin(m);

    li {
      list-style-type: none;
    }
  }

  &__sign_in {
    margin-top: get-margin(xl);
    display: flex;
    justify-content: center;

    a {
      font-size: get-font(l);
      text-decoration: none;
      color: get-color('primary');
      margin-left: get-margin(m);

      &:hover {
        color: get-color('black-color');
      }

      &:disabled {
        color: get-color('text-grey');
      }
    }

  }

  &__submit_margin {
    margin-top: get-margin(xxl);
  }

  &__xsola_button_signUp {
    margin-top: get-margin(m);
  }

  &__layout{
    min-height: 480px !important;
    padding-bottom: get-padding(xxxxl) !important;
  }
}

.success {
  &__title {
    font-size: get-font(xxxxl);
    font-weight: 600;
    margin-bottom: get-margin(xl);
  }

  &__icon {
    width: 40px;
    height: 40px;
    background-color: get-color('bg-primary');
    border-radius: get-border-radius(xxl);
    display: flex;
    justify-content: center;
    margin-bottom: get-margin(xl);
    align-items: center;
  }


  &__description {
    color: get-color('text-grey');
    line-height: 20px;
  }

  &__check_icon {
    width: 25px;
    height: 25px;
    color: get-color('primary');
  }

  &__link {
    display: contents;
  }

  &__submit {
    margin-top: 80px;
  }
}

.verify {
  &__title {
    font-size: get-font(xxxxl);
    font-weight: 600;
    margin-bottom: get-margin(l);
  }

  &__input_margin {
    margin-top: get-margin(xl);
  }

  &__submit {
    margin-top: 60px;
  }


  &__description {
    font-size: get-font(17px);
    color:  get-color('text-grey');
    margin-bottom: get-margin(xl);
    font-weight: 400;
    line-height: 25px;
  }
}
