@import "../../../../../styles/helpers/functions";
@import "../../../../../styles/helpers/mixins";
@import "../../../../../styles/helpers/keyframes";
@import "../../../../../styles/settings/variables";
@import "../../../../../styles/settings/classes";
@import "../../../../../styles/settings/overrides";
@import "../../../../../styles/settings/fonts";

.trigger {
  padding: get-padding(m) 0;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: get-padding(xl) 0;
    margin-bottom: get-margin(xl);
    border-bottom: 1px solid get-color(border);

    svg {
      cursor: pointer;
    }

    p {
      color: get-color(option);
      font-weight: 500;
    }
  }

  &__content {
    &--col-2 {
      grid-template-columns: 1fr 1fr;
      align-items: baseline;
    }

    &--col-6 {
      grid-template-columns: 60px 0.5fr 50px 0.75fr 0.5fr 0.55fr;
      align-items: baseline;
    }

    padding-bottom: get-padding(xl);
    display: grid;
    gap: 10px;
    align-items: center;
  }
}
