@import "../../styles/helpers/functions";
@import "../../styles/helpers/mixins";
@import "../../styles/helpers/keyframes";
@import "../../styles/settings/variables";
@import "../../styles/settings/classes";
@import "../../styles/settings/overrides";
@import "../../styles/settings/fonts";

.auth_layout {

  &__auth_container {
    @include column-block;

    margin: 7% auto;
    padding: 60px 110px;
    background-color: get-color(white);
    // box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
    border-radius: get-border-radius(xxl);
    width: 640px;
    min-height: 180px;
    position: relative;
  }
}
