@import "../../styles/helpers/functions";
@import "../../styles/helpers/mixins";
@import "../../styles/helpers/keyframes";
@import "../../styles/settings/variables";
@import "../../styles/settings/classes";
@import "../../styles/settings/overrides";
@import "../../styles/settings/fonts";

.content {
  height: calc(100vh - #{$header-height} - #{$page-title-height} - #{$tabs-height});
  padding: get-padding(xl) $page-padding;
  overflow: auto;
}

.card {
  min-height: 100px;
  border-radius: 10px;
  background-color: get-color(white);
  padding: get-padding(xxl) get-padding(xxxl);
  margin-bottom: 100px;
  filter: drop-shadow(#{'0px 4px 8px rgba(0, 35, 94, 0.06)'});
}
