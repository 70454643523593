$colors: (
  primary: #0073F7,
  secondary: #DDEDFF,
  secondary-hover: #CAE3FF,
  disabled-light-color: #c5d0db,
  disabled-text-light-color: #f7faff,
  background-color: #f7faff,
  red-light-color: rgba(255, 0, 0, 0.04),
  red-color: #eb002f,
  black-color: #202122,
  grey-color: #888d93,
  dark-grey-color: #5D5D60,
  grey-color-150: #a5aaaf,
  grey-color-100: #d5d9dd,
  grey-color-50: #e1e5ea,
  grey-color-25: #eceff3,
  bg-black: #373737,
  active-bg-btn-grey: #464753,
  white: #ffffff,
  blue-white: #F3F9FF,
  text-primary: #18171C,
  text-secondary: rgba(#18171C, 0.7),
  text-primary-bg: rgba(#18171C, 0.05),
  text-grey: rgba(#18171C, 0.55),
  bg-grey: rgba(#18171C, 0.07),
  bg-primary: rgba(#0073F7, 0.10),
  border: rgba(#18171C, 0.15),
  success: #0F8A65,
  danger: #CC4100,
  info: #3D46F5,
  light-gray: #F1F2F3,
  option: rgba(24, 23, 28, 0.55),
  warning: #CC4100,
  warning-bg: #FFF5E5,
  error-bg: #FDE3E8,
  primary-hover: #0060CE,
  placeholder: #807F82,
  header-bg: #222223,
  header-grey: #C7CAD5,
  separator: #383842,
  header-light-black: #2F2F37
);

$card-box-shadow: #{'0px 1px 2px rgba(7, 7, 8, 0.2)'}, #{'0px 2px 6px 2px rgba(7, 7, 8, 0.1)'};

$margins: (
  s: 5px,
  m: 10px,
  l: 15px,
  xl: 20px,
  xxl: 30px,
  xxxl: 40px,
  xxxxl: 50px,
  test: 600px,
);

$paddings: (
  s: 5px,
  m: 10px,
  l: 15px,
  xl: 20px,
  xxl: 30px,
  xxxl: 40px,
  xxxxl: 50px,
);

$border-radiuses: (
  s: 4px,
  m: 8px,
  l: 12px,
  xl: 16px,
  xxl: 20px,
  xxxl: 24px,
  xxxxl: 28px,
);


$font-sizes: (
  s: 10px,
  m: 13px,
  l: 15px,
  xl: 18px,
  xxl: 20px,
  xxxl: 25px,
  xxxxl: 30px,
  title: 27px,
  description: 17px,
);

$small_mobile: 320px;
$medium_mobile: 360px;
$mobile: 500px;
$tablet: 768px;
$large_tablet: 990px;
$small_desktop: 1024px;
$desktop: 1200px;
$medium_desktop: 1440px;
$large_desktop: 1920px;
$ultrawide_desktop: 2560px;
$xxl_desktop: 3840px;

$navbar-height: 110px;
$navbar-desktop-height: 90px;
$navbar-mobile-height: 70px;

$page-padding: get-padding(xxxl);

$font-family: "Graphik LCG";

$header-height: 64px;
$page-title-height: 80px;
$tabs-height: 46px;
