.page-layout-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  font-family: var(--font-family);
}

.header-content-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--background-color);
}

.content-container {
  flex: 1;
  position: relative;
  min-height: calc(100vh - 64px);
}
