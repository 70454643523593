@import "../../styles/helpers/functions";
@import "../../styles/helpers/mixins";
@import "../../styles/helpers/keyframes";
@import "../../styles/settings/variables";
@import "../../styles/settings/classes";
@import "../../styles/settings/overrides";
@import "../../styles/settings/fonts";

.projects_dropdown {
  @include inline-block;

  margin: get-margin(m);
  margin-left: - get-margin(m);
  padding: get-padding(m);
  position: relative;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.06);
    border-radius: 4px;
  }

  &__logo {
    width: 40px;
    height: 40px;
  }

  &__info {
    margin-left: get-margin(m);
    align-self: center;
  }

  &__name {
    @include inline-block;

    align-items: center;
    font-size: get-font(l);
    line-height: get-font(xxl);
    font-weight: 500;
    color: get-color('white');

    span {
      margin-right: get-margin(s);
    }
  }

  &__id {
    font-size: get-font(m);
    line-height: get-font(xxl);
    font-weight: 400;
    color: get-color('placeholder');
  }
}

.header_container {
  @include inline-block;

  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 40px;
  height: 70px;
  border-bottom: 1px solid var(--disabled-light-color);
  background-color: get-color(header-bg);

  &__avatar_container {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: get-color(header-light-black);
    z-index: 3;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 0;

  }
}

.header_left {
  @include inline-block;

  height: 100%;
  font-size: 24px;
}

.header_right {
  @include inline-block;

  align-items: center;

  a {
    text-decoration: none;
  }
}

.header_right_item {
  @include inline-block;
}

.header_right_item_content {
  @include inline-block;
  color: get-color('header-grey');
  align-items: center;
  cursor: pointer;
  
  &:hover {
    color: white;
  }
}

.header_right_item_margin {
  margin-left: 20px;
}

.header_right_item_text {
  align-self: center;
  margin-left: 9px;
  font-size: get-font(l);
  font-weight: 500;
}

.header_right_icon {
  width: 24px;
  height: 24px;
}

.header_right_user_icon {
  font-size: 30px;
  margin-left: 20px;
  width: 40px;
  height: 40px;
  border: 1px solid white;
  border-radius: 50%;
}

.header_dropdown {
  position: absolute;
  top: 55px;
  background-color: white;
  color: get-color('primary-text');
  padding: get-padding(m);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.header_dropdown_right {
  right: 10px;
}

.header_dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header_dropdown a {
  padding: get-padding(s) 0;
  text-decoration: none;
  color: initial;
}

.header_dropdown li {
  padding: get-padding(s) 0;
}

.header_dropdown li:hover {
  background-color: #f2f2f2;
}

.separator {
  margin: get-margin(xxl);
  height: 70px;
  border-left: 0.5px solid get-color('separator');
}