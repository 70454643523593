@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

.wrapper {
  &__control {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-size: get-font(l);
  }

  &__input {
    outline: none !important;
    width: 100%;
    border: 1px solid get-color("border");
    border-radius: get-border-radius(s);
    padding: 9px 15px;
    font-size: get-font(l);
    font-family: "Graphik LCG", serif;

    &:focus {
      border: 1px solid get-color("black-color") !important;
    }

    &[data-invalid] {
      border: 1px solid get-color("red-color") !important;
    }
  }

  &__error_triangle {
    position: absolute;
    color: get-color(red-color);
    right: 12px;
    bottom: 9px;
  }

  &__error_label {
    display: flex;
    color: var(--red-color);
    font-size: 12px;
    margin-top: 4px;
  }

}

.switch {

  &__switch_root {
    width: 42px;
    height: 25px;
    background-color: get-color('primary');
    border-radius: 9999px;
    position: relative;
    box-shadow: 0 2px 10px var(--blackA7);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;

    &:hover {
      background-color: get-color("primary-hover")
    }

    &[data-state='unchecked'] {
      background-color: get-color('grey-color-100')
    }
  }

  &__switch_thumb {
    display: block;
    width: 21px;
    height: 21px;
    background-color: white;
    border-radius: 9999px;
    transition: transform 100ms;
    transform: translateX(2px);
    will-change: transform;

    &[data-state='checked'] {
      transform: translateX(19px);
    }
  }
}
