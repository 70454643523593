@import "../../styles/helpers/functions";
@import "../../styles/helpers/mixins";
@import "../../styles/helpers/keyframes";
@import "../../styles/settings/variables";
@import "../../styles/settings/classes";
@import "../../styles/settings/overrides";
@import "../../styles/settings/fonts";

.sign_up {

  &__title {
    font-size: get-font(xxxxl);
    font-weight: 600;
    margin-bottom: get-margin(l);
  }

  &__description {
    font-size: get-font(l);
    color:  get-color('text-grey');
    margin-bottom: get-margin(s);
  }

  &__form {
    @include column-block;
  }

  &__submit {
    margin-top: 80px !important;
  }

  &__helper {
    padding-left: get-padding(xl);
    color: get-color('text-grey');
    font-size: 13px;
    margin-top: get-margin(m);

    li {
      list-style-type: none;
    }
  }

  &__layout{
    height: 500px;
  }
}
