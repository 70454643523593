@import "../../styles/helpers/functions";
@import "../../styles/helpers/mixins";
@import "../../styles/helpers/keyframes";
@import "../../styles/settings/variables";
@import "../../styles/settings/classes";
@import "../../styles/settings/overrides";
@import "../../styles/settings/fonts";

.login {

  &__title {
    font-size: get-font(title);
    font-weight: 600;
    margin-bottom: get-margin(xxl);
  }

  &__form {
    @include column-block;
  }

  &__row_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: get-margin(xl);
  }

  &__forgot_password_link {
    font-size: get-font(l);
    text-decoration: none;
    color: get-color('primary');

    &:hover {
      color: get-color('black-color');
    }

    &:disabled {
      color: get-color('text-grey');
    }
  }

  &__sign_up{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    
    a {
      font-size: get-font(l);
      text-decoration: none;
      color: get-color('primary');
      margin-left: 10px;

      &:hover {
        color: get-color('black-color');
      }
  
      &:disabled {
        color: get-color('text-grey');
      }
    }
  }

  &__submit_login_margin {
    margin-top: get-margin(xxl);
  }

  &__xsola_button_login {
    margin-top: get-margin(m);
  }

  &__layout{
    min-height: 480px !important;
    padding-bottom: get-padding(xxxxl) !important;
  }
}
