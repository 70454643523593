@import "../../../../styles/helpers/functions";
@import "../../../../styles/helpers/mixins";
@import "../../../../styles/helpers/keyframes";
@import "../../../../styles/settings/variables";
@import "../../../../styles/settings/classes";
@import "../../../../styles/settings/overrides";
@import "../../../../styles/settings/fonts";

@mixin title {
  font-size: get-font(l);
  font-weight: 500;
}

.item_property {
  display: grid;
  grid-template-columns: 1fr 640px 1fr;

  &__title {
    font-size: get-font(xxl);
    font-weight: 600;
  }

  &__content {
    @include column-block;

    &--col-2 {
      grid-template-columns: 1fr 1fr;
    }

    gap: 30px;


    &__helper {
      padding-left: get-padding(xl);
      color: var(--grey-color);
      font-size: 13px;
      margin-top: get-margin(m);
  
      li {
        list-style-type: none;
      }
    }

    &__image_uploader {
      @include inline-block;

      gap: 15px;
    }

    &__radio {
      &__title {
        @include title;

        &__clickable {
          padding-top: get-padding(m);
          cursor: pointer;
          color: get-color(red-color);
        }
      }

      &__radios {
        &--col-2 {
          grid-template-columns: repeat(2, 1fr);
        }

        &--col-3 {
          grid-template-columns: repeat(3, 1fr);
        }

        display: grid;
        gap: 10px;
      }
    }

    &__multiselect {
      &__title {
        @include title;

        padding-bottom: get-padding(m);
      }
    }

    &__inputs {
      gap: 10px;
      display: grid;
      align-items: center;
    }
  }
}
