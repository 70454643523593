@import "../../styles/helpers/functions";
@import "../../styles/helpers/mixins";
@import "../../styles/helpers/keyframes";
@import "../../styles/settings/variables";
@import "../../styles/settings/classes";
@import "../../styles/settings/overrides";
@import "../../styles/settings/fonts";

$spinner-size: 50px;

.spinner {
  position: absolute;
  top: calc(50% - #{$spinner-size / 2});
  left: calc(50% - #{$spinner-size / 2});
  transform: translate(-50%, -50%);
  width: $spinner-size;
  height: $spinner-size;
  animation: spin 1s linear infinite;

  &--small {
    $spinner-size: 20px;

    top: calc(50% - #{$spinner-size / 2});
    left: calc(50% - #{$spinner-size / 2});
    width: $spinner-size;
    height: $spinner-size;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
