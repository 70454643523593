@import "../../styles/helpers/functions";
@import "../../styles/helpers/mixins";
@import "../../styles/helpers/keyframes";
@import "../../styles/settings/variables";
@import "../../styles/settings/classes";
@import "../../styles/settings/overrides";
@import "../../styles/settings/fonts";

.navigation {
  @include column-block;

  background-color: get-color('background-color');
  min-height: 100%;
  width: 180px;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 20px;
  border-right: 1px solid get-color('disabled-light-color');

  &__item {
    margin-bottom: 10px;
  }


  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      height: 40px;
    }
  }

  a {
    display: block;
    padding: 10px 25px;
    text-decoration: none;
    color: #202122;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    &:hover {
      background-color: get-color('primary');
      color: white;
    }
  }
}

.active {
  background-color: get-color('primary');
  font-weight: 500;
  color: white;
}

.nav_item {
  @include inline-block;

  align-items: center;

  svg {
    margin-right: 5px;
  }
}

.logo {
  margin-top: auto;
  margin-bottom: get-margin(xxl) !important;
  color: #C7CAD5;
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
}
