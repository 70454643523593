@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";

.uploader {
  @include column-block;

  background: get-color("blue-white");
  width: 150px;
  height: 150px;
  gap: 15px;
  border: 1px dashed get-color("primary");
  border-radius: get-border-radius(m);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &__title {
    font-size: get-font(l);
    font-weight: 500;
  }

  &__buttons_block {
    @include column-block;

    position: absolute;
    top: 55px;
    opacity: 0;
    gap: get-padding(m);
  }

  &__uploaded_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &:hover {
    .uploader__uploaded_image {
      opacity: 0.1;
    }
    .uploader__buttons_block {
      opacity: 1;
    }
  }
}


.unvisible_uploader {
  display: none;
}
